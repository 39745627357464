import React from 'react';


class Intro extends React.Component {

    render() {
        return (
            <div id="intro">
                <div className="title1">Nabil Abdellaoui</div>
                <div className="title2">The Random Bishop</div>
                <div className="text">
                    <p>I developed a passion for computers and programming early on, beginning at age 11. 
                    Since then, I’ve built a career doing what I love.</p>

                    <p>After completing my studies in Mathematics and Psychology, I co-founded a freelance office and worked across a wide range of software applications. 
                    One of my key projects was developing a SaaS platform for an insurance company in Morocco, designed for automobile claim processing and cost analysis. 
                    This product was later acquired by a brand of Solera, the global leader in the industry.</p>

                    <p>I then specialized in Machine Learning, working with startups in the credit space. 
                    At Tala, a company providing loans to underserved populations, 
                    I led the end-to-end development, deployment, and monitoring of ML models, 
                    which enabled us to serve over 5 million customers with more than $2 billion in loans.</p>

                    <p>In 2021, I entered the world of blockchain and decentralized finance, 
                    joining projects with Gauntlet Network, Web3 Foundation, and Blueprint Finance. 
                    My focus was on simulating and managing risk for DeFi protocols like Aave and Compound.</p>

                    <p>Today, I’m exploring the frontier where machine learning, AI, and decentralized systems converge, 
                    creating applications that challenge the limits of what these technologies can achieve together. 
                    My latest project is a <a href="https://app.datascience.art">data analytics app</a> for Farcaster, 
                    a decentralized social media platform, where I apply advanced AI and blockchain techniques to reimagine data-driven engagement. 
                    </p>

                    <p>As an active member of the Bitcoin, Ethereum, and Polkadot communities, 
                    I’m dedicated to pushing beyond conventional applications, 
continually experimenting across diverse technologies to shape what’s next in software innovation.</p>
                </div>
                <div className="title2">Links</div>
                <div className="text">
                    <ul>
                        <li><a href="https://app.datascience.art">Farcaster Data Analytics</a></li>
                        <br/>
                        <li><a href="https://gdp.datascience.art">World GDP Center</a></li>
                        <br/>
                        <li><a href="https://www.datascience.art/MemoireDEA_NabilAbdellaoui.pdf">My research thesis from 2004</a></li>
                    </ul>
                  </div>
            </div>
        )
    }

}

export default Intro;
